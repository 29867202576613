import React, { useState, useRef, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../assets/Logo/mdiatech-logo-0d0cb5.png';

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState({});

  const mobileMenuRef = useRef(null); // Reference to the mobile menu for click outside detection

  const toggleMenu = (e) => {
    e.preventDefault();
    if (menuActive) {
      document.body.classList.remove('offcanvas-menu');
    } else {
      document.body.classList.add('offcanvas-menu');
    }
    setMenuActive(!menuActive);
  };

  const toggleDropdown = (key) => {
    setDropdownActive(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleNavLinkClick = () => {
    if (menuActive) {
        document.body.classList.remove('offcanvas-menu');
        setMenuActive(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 768 && menuActive) {
        document.body.classList.remove('offcanvas-menu');
        setMenuActive(false);
      }
    };

    const handleClickOutside = (e) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(e.target) && menuActive) {
        document.body.classList.remove('offcanvas-menu');
        setMenuActive(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuActive]);


  const renderMenu = (isMobile = false) => (
    <ul className={isMobile ? "site-nav-wrap" : "site-menu js-clone-nav mr-auto d-none d-lg-block"}>
      <li><NavLink exact to="/" onClick={handleNavLinkClick}>Accueil</NavLink></li>
      <li><NavLink exact to="/about" onClick={handleNavLinkClick}>A propos</NavLink></li>
      <li className="has-children">
        <NavLink exact to="/services" onClick={handleNavLinkClick}>Offres</NavLink>
        {isMobile &&
          <span className={`arrow-collapse ${dropdownActive['services'] ? 'active' : 'collapsed'}`}
            onClick={() => toggleDropdown('services')}></span>
        }
        <ul className={isMobile
          ? `collapse ${dropdownActive['services'] ? 'show' : ''}`
          : `dropdown ${dropdownActive['services'] ? 'show' : ''}`}>
          <li><NavLink exact to="/services-microsoft-solutions" onClick={handleNavLinkClick}>Solutions et Infrastructures Microsoft</NavLink></li>
          <li><NavLink exact to="/services-collaboration-productivity" onClick={handleNavLinkClick}>Collaboration et Productivité</NavLink></li>
          <li><NavLink exact to="/services-devops-security" onClick={handleNavLinkClick}>DevOps, Sécurité et Conformité</NavLink></li>
          <li><NavLink exact to="/services-development-management" onClick={handleNavLinkClick}>Développement et Gestion de Projet</NavLink></li>
        </ul>
      </li>
      <li><NavLink exact to="/client-references" onClick={handleNavLinkClick}>Réfèrences</NavLink></li>
      <li className="has-children">
        <NavLink exact to="/partners" onClick={handleNavLinkClick}>Partenaires</NavLink>
        {isMobile &&
          <span className={`arrow-collapse ${dropdownActive['partners'] ? 'active' : 'collapsed'}`}
            onClick={() => toggleDropdown('partners')}></span>
        }
        <ul className={isMobile
          ? `collapse ${dropdownActive['partners'] ? 'show' : ''}`
          : `dropdown ${dropdownActive['partners'] ? 'show' : ''}`}>
          <li><NavLink exact to="/partners-eml-services-data-strength" onClick={handleNavLinkClick}>Solutions Data EML</NavLink></li>
          <li><NavLink exact to="/partners-eml-services-digital-solutions" onClick={handleNavLinkClick}>Solutions Digitales EML</NavLink></li>
          <li><NavLink exact to="/partners-eml-services-business-solutions" onClick={handleNavLinkClick}>Solutions Métiers Bancaires EML</NavLink></li>
        </ul>
      </li>
      <li><NavLink to="/contact" onClick={handleNavLinkClick}>Contact</NavLink></li>
    </ul>
  );

  return (
    <div className="site-header">
      <div className={`site-mobile-menu ${menuActive ? 'active' : ''}`} ref={mobileMenuRef}>
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle" onClick={toggleMenu}></span>
          </div>
        </div>
        <div className="site-mobile-menu-body">
          {renderMenu(true)}
        </div>
      </div>
      <div className="border-bottom top-bar py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="mb-0">
                {/*<span className="mr-3"><strong>Tél:</strong><a href="tel:+33632957668"> +33 6 32 95 76 68</a></span>*/}
                <span><strong>Email:</strong><a href="mailto:contact@mdiatech.com"> contact@mdiatech.com</a></span>
              </p>
            </div>
            <div className="col-md-6">
              <ul className="social-media">
                <li><a href="https://www.linkedin.com/in/md22304/" className="p-2"><span className="icon-linkedin"></span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header className="site-navbar py-4 bg-white" role="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-11 col-xl-2">
              <Link to="/"><img src={logo} style={{ height: 50 }} alt="MdiaTech Logo" /></Link>
            </div>
            <div className="col-12 col-md-10 d-none d-xl-block">
              <nav className="site-navigation position-relative text-right" role="navigation">
                {renderMenu()}
              </nav>
            </div>
            <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3" style={{ position: 'relative', top: '3px' }}>
              <button onClick={toggleMenu} className="site-menu-toggle text-black"><span className="icon-menu h3"></span></button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
